/* Component Dependencies */
var localAttractionTemplate = require('templates/localAttractions.hbs');
var localAttractionAreaSightseeingTemplate = require('templates/localAttractions-areaSightseeing.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'localAttractions',
  template: {
    'localAttractions-areaSightseeing': localAttractionAreaSightseeingTemplate,
    'localAttractions': localAttractionTemplate
  },
  bindEvents: function () {
    var _self = this;
    if(!(_self.$el.find('.tile-local-attractions').length)) {
      _self.$el.closest('.mi-sub-section').addClass('mi-sub-section-blank');
      _self.$el.remove();
    }
  }
});